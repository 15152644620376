import { Button } from "components/button";
import { CardWithTitle } from "components/card/CardWithTitle";
import InputField from "components/fields/InputField";
import { useCreateExercise } from "modules/exercises/api";
import { FormEvent, useState } from "react";
import Upload from "views/admin/profile/components/Upload";

const CreateExercise = () => {
  const { mutate } = useCreateExercise();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image1, setImage1] = useState(undefined);
  const [image2, setImage2] = useState(undefined);
  const [video, setVideo] = useState(undefined);

  const isSubmitDisabled = !name || !description || !image1 || !video;

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (isSubmitDisabled) {
      return;
    }

    mutate({
      name,
      description,
      image1: image1 as File,
      image2: image2 as File,
      video: video as File,
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <CardWithTitle title="Informations sur l'exercise">
        <div className="grid grid-cols-2 gap-5">
          <InputField
            variant="auth"
            extra="mb-3"
            label="Nom de l'exercise*"
            placeholder="Squat"
            id="nom-de-lexercise"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Description*"
            placeholder="
            lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
            accumsan, metus ultrices eleifend gravida, nulla nunc varius lectus,
            "
            rows={4}
            id="description"
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </CardWithTitle>
      <CardWithTitle title="Ajouter des photos de l'exercise">
        <div className="grid grid-cols-2 gap-5">
          <div>
            <Upload
              uploadImage={(file) => {
                setImage1(file);
              }}
              image={image1}
            />
          </div>
          <div>
            <Upload uploadImage={(file) => setImage2(file)} image={image2} />
          </div>
        </div>
      </CardWithTitle>
      <CardWithTitle title="Ajouter une vidéo de l'exercise">
        <div className="grid grid-cols-2 gap-5">
          <div>
            <Upload
              uploadImage={(file) => setVideo(file)}
              name="Télécharger une vidéo"
              description="Uniquement les fichiers MP4 sont autorisés"
              video={video}
            />
          </div>
        </div>
      </CardWithTitle>
      <form className="mt-2 flex w-full justify-end" onSubmit={handleSubmit}>
        <div className="w-full max-w-xs">
          <Button onClick={handleSubmit} disabled={isSubmitDisabled}>
            Créer l'exercice
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CreateExercise;
