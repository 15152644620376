import { SuccessResponse } from "common/common.interface";
import { useSnack } from "common/hooks";
import { useInfiniteQueryWithLoading } from "common/hooks/src/api/useInfiniteQueryWithLoading";
import { useMutationWithLoading } from "common/hooks/src/api/useMutationWithLoading";
import { useQueryWithLoading } from "common/hooks/src/api/useQueryWithLoading";
import {
  CreateExercisePayload,
  Exercise,
  Pagination,
} from "modules/exercises/types/exercises.interface";
import {
  UseInfiniteQueryResult,
  UseMutationResult,
  UseQueryResult,
  useQueryClient,
} from "react-query";
import { useNavigate } from "react-router-dom";
import { getExercisesBackend } from "./backends";
import { EXERCISE_ROUTES } from "./exercises.api.enum";

export const useGetExercises = ({
  searchName,
  limit,
}: {
  searchName?: string | undefined;
  limit?: number;
}): UseInfiniteQueryResult<Pagination<Exercise>> => {
  const setSnack = useSnack();

  const getExercises = async ({
    pageParam = 1,
  }): Promise<Pagination<Exercise>> => {
    const notificationsBackend = await getExercisesBackend();
    return await notificationsBackend.getExercises(
      pageParam,
      limit || 10,
      searchName
    );
  };

  return useInfiniteQueryWithLoading(
    EXERCISE_ROUTES.GET_ALL + searchName,
    getExercises,
    {
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useGetExercise = (
  id: string | undefined
): UseQueryResult<Exercise> => {
  const setSnack = useSnack();

  const getExercise = async (): Promise<Exercise> => {
    const exerciseBackend = await getExercisesBackend();
    return await exerciseBackend.getExercise(id);
  };

  return useQueryWithLoading(EXERCISE_ROUTES.GET + id, () => getExercise(), {
    onError() {
      setSnack({
        title: "Erreur interne!",
        severityType: "error",
      });
    },
    enabled: !!id,
  });
};

export const useCreateExercise = (): UseMutationResult<
  SuccessResponse<Exercise>,
  Error,
  CreateExercisePayload
> => {
  const setSnack = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const createExercise = async (
    payload: CreateExercisePayload
  ): Promise<SuccessResponse<Exercise>> => {
    const exerciseBackend = await getExercisesBackend();
    return await exerciseBackend.createExercise(payload);
  };

  return useMutationWithLoading(
    (payload: CreateExercisePayload) => createExercise(payload),
    {
      onSuccess() {
        setSnack({
          title: "Exercise créé avec succès!",
          severityType: "success",
        });

        queryClient.refetchQueries(EXERCISE_ROUTES.GET_ALL);

        navigate("/admin/exercice");
      },
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useUpdateExercise = (
  id: string | undefined
): UseMutationResult<Exercise, Error, CreateExercisePayload> => {
  const setSnack = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const updateExercise = async (
    payload: CreateExercisePayload
  ): Promise<Exercise> => {
    const exerciseBackend = await getExercisesBackend();
    return await exerciseBackend.updateExercise(id, payload);
  };

  return useMutationWithLoading(
    (payload: CreateExercisePayload) => updateExercise(payload),
    {
      onSuccess() {
        setSnack({
          title: "Exercise modifié avec succès!",
          severityType: "success",
        });

        queryClient.refetchQueries(EXERCISE_ROUTES.GET_ALL);

        navigate("/admin/exercice");
      },
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useRemoveExercise = (): UseMutationResult<
  SuccessResponse,
  Error,
  string
> => {
  const setSnack = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const removeExercise = async (id: string): Promise<SuccessResponse> => {
    const exerciseBackend = await getExercisesBackend();
    return await exerciseBackend.removeExercise(id);
  };

  return useMutationWithLoading((id: string) => removeExercise(id), {
    onSuccess() {
      setSnack({
        title: "Exercise modifié avec succès!",
        severityType: "success",
      });

      queryClient.refetchQueries(EXERCISE_ROUTES.GET_ALL);

      navigate("/admin/exercise");
    },
    onError() {
      setSnack({
        title: "Erreur interne!",
        severityType: "error",
      });
    },
  });
};
