import { SuccessResponse } from "common/common.interface";
import { useSnack } from "common/hooks";
import { useInfiniteQueryWithLoading } from "common/hooks/src/api/useInfiniteQueryWithLoading";
import { useMutationWithLoading } from "common/hooks/src/api/useMutationWithLoading";
import { useQueryWithLoading } from "common/hooks/src/api/useQueryWithLoading";
import { Pagination } from "modules/exercises/types/exercises.interface";
import {
  CreateProtocolPayload,
  Protocol,
} from "modules/protocol/types/protocol.interface";
import {
  UseInfiniteQueryResult,
  UseMutationResult,
  UseQueryResult,
  useQueryClient,
} from "react-query";
import { useNavigate } from "react-router-dom";
import { getProtocolBackend } from "./backends";
import { PROTOCOL_ROUTES } from "./protocol.api.enum";

export const useGetProtocols = (
  searchName: string | undefined
): UseInfiniteQueryResult<Pagination<Protocol>> => {
  const setSnack = useSnack();

  const getExercises = async ({
    pageParam = 1,
  }): Promise<Pagination<Protocol>> => {
    const notificationsBackend = await getProtocolBackend();
    return await notificationsBackend.getProtocols(pageParam, 10, searchName);
  };

  return useInfiniteQueryWithLoading(
    PROTOCOL_ROUTES.GET_ALL + searchName,
    getExercises,
    {
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useGetProtocol = (
  id: string | undefined
): UseQueryResult<Protocol> => {
  const setSnack = useSnack();

  const getProtocol = async (): Promise<Protocol> => {
    const protocolBackend = await getProtocolBackend();
    return await protocolBackend.getProtocol(id);
  };

  return useQueryWithLoading(PROTOCOL_ROUTES.GET + id, () => getProtocol(), {
    onError() {
      setSnack({
        title: "Erreur interne!",
        severityType: "error",
      });
    },
    enabled: !!id,
  });
};

export const useCreateProtocol = (): UseMutationResult<
  Protocol,
  Error,
  CreateProtocolPayload
> => {
  const setSnack = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const createProtocol = async (
    payload: CreateProtocolPayload
  ): Promise<Protocol> => {
    const protocolBackend = await getProtocolBackend();
    return await protocolBackend.createProtocol(payload);
  };

  return useMutationWithLoading(
    (payload: CreateProtocolPayload) => createProtocol(payload),
    {
      onSuccess() {
        setSnack({
          title: "Protocol créé avec succès!",
          severityType: "success",
        });

        queryClient.refetchQueries(PROTOCOL_ROUTES.GET_ALL);

        navigate("/admin/protocol");
      },
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useUpdateProtocol = (
  id: string | undefined
): UseMutationResult<Protocol, Error, CreateProtocolPayload> => {
  const setSnack = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const updateProtocol = async (
    payload: CreateProtocolPayload
  ): Promise<Protocol> => {
    const protocolBackend = await getProtocolBackend();
    return await protocolBackend.updateProtocol(id, payload);
  };

  return useMutationWithLoading(
    (payload: CreateProtocolPayload) => updateProtocol(payload),
    {
      onSuccess() {
        setSnack({
          title: "Protocol modifié avec succès!",
          severityType: "success",
        });

        queryClient.refetchQueries(PROTOCOL_ROUTES.GET_ALL);

        navigate("/admin/protocol");
      },
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useRemoveProtocol = (): UseMutationResult<
  SuccessResponse,
  Error,
  string
> => {
  const setSnack = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const removeProtocol = async (id: string): Promise<SuccessResponse> => {
    const protocolBackend = await getProtocolBackend();
    return await protocolBackend.removeProtocol(id);
  };

  return useMutationWithLoading((id: string) => removeProtocol(id), {
    onSuccess() {
      setSnack({
        title: "Protocol supprimer avec succès!",
        severityType: "success",
      });

      queryClient.refetchQueries(PROTOCOL_ROUTES.GET_ALL);

      navigate("/admin/protocol");
    },
    onError() {
      setSnack({
        title: "Erreur interne!",
        severityType: "error",
      });
    },
  });
};
