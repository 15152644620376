import Card from "components/card";
import NftCard from "components/card/NftCard";
import { useGetExercises } from "modules/exercises/api";
import { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { MdArrowLeft, MdArrowRight } from "react-icons/md";

const Exercises = () => {
  const [searchName, setSearchName] = useState<string | undefined>(undefined);
  const { data, hasNextPage, fetchNextPage } = useGetExercises({ searchName });
  const totalPage = data?.pages[0].count;
  const [currentPage, setCurrentPage] = useState(1);
  const flatData = data?.pages[currentPage - 1]?.data;

  const handleFetchNextPage = () => {
    if (hasNextPage) {
      fetchNextPage();
      setCurrentPage(currentPage + 1);
    }
  };

  const handleFetchPrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto mt-5"}>
        <div className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Exercices
          </div>
          <div className="flex items-center gap-4">
            <div className="flex h-full items-center rounded-full bg-lightPrimary py-3.5 text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[250px]">
              <p className="pl-3 pr-2 text-xl">
                <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
              </p>
              <input
                type="text"
                placeholder="Search..."
                className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
              />
            </div>
            <a
              className="cursor-pointer rounded-full bg-brand-500 p-0.5 text-white"
              href="/admin/exercice/create"
            >
              <svg
                className="h-8 w-8 cursor-pointer"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="mt-5">
          <div className="z-20 grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5">
            {flatData &&
              flatData?.map((exercise) => (
                <NftCard
                  key={exercise._id}
                  title={exercise.name}
                  image={exercise.photoUrls[0]}
                  video={exercise.videoUrl}
                  id={exercise._id}
                />
              ))}
          </div>
          <div className="mt-5 flex items-center justify-end">
            <MdArrowLeft
              onClick={handleFetchPrevPage}
              className={`h-8 w-8 cursor-pointer ${
                currentPage - 1 > 0 ? "text-brand-500" : "text-gray-400"
              }`}
            />
            <p className="text-sm text-navy-700 dark:text-white">
              Page {currentPage} sur {totalPage}
            </p>
            <MdArrowRight
              onClick={handleFetchNextPage}
              className={`h-8 w-8 cursor-pointer ${
                hasNextPage ? "text-brand-500" : "text-gray-400"
              }`}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Exercises;
