import { SuccessResponse } from "common/common.interface";
import { useSnack } from "common/hooks";
import { useInfiniteQueryWithLoading } from "common/hooks/src/api/useInfiniteQueryWithLoading";
import { useMutationWithLoading } from "common/hooks/src/api/useMutationWithLoading";
import { useQueryWithLoading } from "common/hooks/src/api/useQueryWithLoading";
import { Pagination } from "modules/exercises/types/exercises.interface";
import {
  CreateTabletPayload,
  Tablet,
} from "modules/tablet/types/tablet.interface";
import {
  UseInfiniteQueryResult,
  UseMutationResult,
  UseQueryResult,
  useQueryClient,
} from "react-query";
import { useNavigate } from "react-router-dom";
import { getTabletBackend } from "./backends";
import { TABLET_ROUTES } from "./tablet.api.enum";

export const useGetTablets = (
  searchName: string | undefined
): UseInfiniteQueryResult<Pagination<Tablet>> => {
  const setSnack = useSnack();

  const getExercises = async ({
    pageParam = 1,
  }): Promise<Pagination<Tablet>> => {
    const notificationsBackend = await getTabletBackend();
    return await notificationsBackend.getTablets(pageParam, 10, searchName);
  };

  return useInfiniteQueryWithLoading(
    TABLET_ROUTES.GET_ALL + searchName,
    getExercises,
    {
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useGetTablet = (
  id: string | undefined
): UseQueryResult<Tablet> => {
  const setSnack = useSnack();

  const getTablet = async (): Promise<Tablet> => {
    const tabletBackend = await getTabletBackend();
    return await tabletBackend.getTablet(id);
  };

  return useQueryWithLoading(TABLET_ROUTES.GET + id, () => getTablet(), {
    onError() {
      setSnack({
        title: "Erreur interne!",
        severityType: "error",
      });
    },
    enabled: !!id,
  });
};

export const useCreateTablet = (): UseMutationResult<
  Tablet,
  Error,
  CreateTabletPayload
> => {
  const setSnack = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const createTablet = async (
    payload: CreateTabletPayload
  ): Promise<Tablet> => {
    const tabletBackend = await getTabletBackend();
    return await tabletBackend.createTablet(payload);
  };

  return useMutationWithLoading(
    (payload: CreateTabletPayload) => createTablet(payload),
    {
      onSuccess() {
        setSnack({
          title: "Tablet créer avec succès!",
          severityType: "success",
        });

        queryClient.refetchQueries(TABLET_ROUTES.GET_ALL);

        navigate("/admin/tablet");
      },
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useUpdateTablet = (
  id: string | undefined
): UseMutationResult<Tablet, Error, CreateTabletPayload> => {
  const setSnack = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const updateTablet = async (
    payload: CreateTabletPayload
  ): Promise<Tablet> => {
    const tabletBackend = await getTabletBackend();
    return await tabletBackend.updateTablet(id, payload);
  };

  return useMutationWithLoading(
    (payload: CreateTabletPayload) => updateTablet(payload),
    {
      onSuccess() {
        setSnack({
          title: "Tablet modifié avec succès!",
          severityType: "success",
        });

        queryClient.refetchQueries(TABLET_ROUTES.GET_ALL);

        navigate("/admin/tablet");
      },
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useRemoveTablet = (): UseMutationResult<
  SuccessResponse,
  Error,
  string
> => {
  const setSnack = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const removeTablet = async (id: string): Promise<SuccessResponse> => {
    const tabletBackend = await getTabletBackend();
    return await tabletBackend.removeTablet(id);
  };

  return useMutationWithLoading((id: string) => removeTablet(id), {
    onSuccess() {
      setSnack({
        title: "Tablet supprimer avec succès!",
        severityType: "success",
      });

      queryClient.refetchQueries(TABLET_ROUTES.GET_ALL);

      navigate("/admin/tablet");
    },
    onError() {
      setSnack({
        title: "Erreur interne!",
        severityType: "error",
      });
    },
  });
};
