import { FunctionComponent, useEffect } from "react";

interface SelectProps {
  options: string[];
  value: string;
  onChange: (e: any) => void;
  label: string;
}

export const Select: FunctionComponent<SelectProps> = ({
  label,
  onChange,
  options,
  value,
}) => {

  useEffect(() => {
    if (!value && options.length === 1) {
      onChange({
        target: {
          value: options[0],
        },
      });
    }
  }, [onChange, options, options.length, value]);

  return (
    <div className="flex flex-col">
      <label
        className={`font-medium" ml-1.5 text-sm text-navy-700 dark:text-white`}
      >
        {label}
      </label>
      <select
        className="mt-2 rounded-xl border border-gray-300 bg-white/0 p-3 text-sm shadow-sm outline-none focus:border-brand-500 focus:ring-brand-500 sm:text-sm"
        value={value}
        onChange={onChange}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};
