import { environment } from "common/environment";
import {
  Cabinet,
  CreateCabinetPayload,
} from "modules/cabinets/types/cabinets.interface";

export interface ICabinetBackend {
  getCabinets(): Promise<Cabinet[]>;
  getCabinet(id: string): Promise<Cabinet>;
  createCabinet(payload: CreateCabinetPayload): Promise<Cabinet>;
  updateCabinet(id: string, payload: CreateCabinetPayload): Promise<Cabinet>;
}

let cabinetBackendInstance: ICabinetBackend | undefined;

export async function getCabinetBackend(): Promise<ICabinetBackend> {
  if (cabinetBackendInstance === undefined) {
    const mod = await import(`./${environment.CABINET_BACKEND}`);
    cabinetBackendInstance = new mod.default() as ICabinetBackend;
  }
  return cabinetBackendInstance;
}
