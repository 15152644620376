// Admin Imports
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdBarChart,
  MdChecklist,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdPerson,
  MdSportsGymnastics,
  MdTabletMac,
} from "react-icons/md";
import Cabinets from "views/admin/cabinets";
import UpdateCabinet from "views/admin/cabinets/[id]";
import CreateCabinets from "views/admin/cabinets/create";
import Exercises from "views/admin/exercices";
import CreateExercise from "views/admin/exercices/create";
import UpdateExercise from "views/admin/exercices/update";
import Protocols from "views/admin/protocol";
import CreateProtocol from "views/admin/protocol/create";
import UpdateProtocol from "views/admin/protocol/update";
import Tablets from "views/admin/tablet";
import CreateTablet from "views/admin/tablet/create";
import UpdateTablet from "views/admin/tablet/update";
import ForgetPassword from "views/auth/ForgetPassword";

const routes = [
  {
    name: "NFT Marketplace",
    layout: "/admin",
    path: "nft-marketplace",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },
  {
    name: "Data Tables",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "data-tables",
    component: <DataTables />,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "Cabinets",
    layout: "/admin",
    path: "cabinets",
    icon: <MdHome className="h-6 w-6" />,
    component: <Cabinets />,
    show: true,
  },
  {
    name: "Ajouter un cabinet",
    layout: "/admin",
    path: "cabinets/create",
    icon: <MdHome className="h-6 w-6" />,
    component: <CreateCabinets />,
  },
  {
    name: "Modifier un cabinet",
    layout: "/admin",
    path: "cabinets/:id",
    icon: <MdHome className="h-6 w-6" />,
    component: <UpdateCabinet />,
  },
  {
    name: "Mots de passe oublié?",
    layout: "/auth",
    path: "forgot-password",
    icon: <MdLock className="h-6 w-6" />,
    component: <ForgetPassword />,
  },
  {
    name: "Exercice",
    layout: "/admin",
    path: "exercice",
    icon: <MdSportsGymnastics className="h-6 w-6" />,
    component: <Exercises />,
    show: true,
  },
  {
    name: "Protocole",
    layout: "/admin",
    path: "protocol",
    icon: <MdChecklist className="h-6 w-6" />,
    component: <Protocols />,
    show: true,
  },
  {
    name: "Création de Protocole",
    layout: "/admin",
    path: "protocol/create",
    icon: <MdChecklist className="h-6 w-6" />,
    component: <CreateProtocol />,
  },
  {
    name: "Mis à jour de Protocole",
    layout: "/admin",
    path: "protocol/:id",
    icon: <MdChecklist className="h-6 w-6" />,
    component: <UpdateProtocol />,
  },
  {
    name: "Create Exercice",
    layout: "/admin",
    path: "exercice/create",
    icon: <MdSportsGymnastics className="h-6 w-6" />,
    component: <CreateExercise />,
  },
  {
    name: "Mis à jour Exercice",
    layout: "/admin",
    path: "exercice/:id",
    icon: <MdSportsGymnastics className="h-6 w-6" />,
    component: <UpdateExercise />,
  },
  {
    name: "Modifier une Tablet",
    layout: "/admin",
    path: "tablet/:id",
    icon: <MdSportsGymnastics className="h-6 w-6" />,
    component: <UpdateTablet />,
  },
  {
    name: "Ajouter une Tablet",
    layout: "/admin",
    path: "tablet/create",
    icon: <MdSportsGymnastics className="h-6 w-6" />,
    component: <CreateTablet />,
  },
  {
    name: "Tablets",
    layout: "/admin",
    path: "tablet",
    icon: <MdTabletMac className="h-6 w-6" />,
    component: <Tablets />,
    show: true,
  },
];
export default routes;
