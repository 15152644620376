import { useSnack } from "common/hooks";
import { useMutationWithLoading } from "common/hooks/src/api/useMutationWithLoading";
import { useQueryWithLoading } from "common/hooks/src/api/useQueryWithLoading";
import {
  Cabinet,
  CreateCabinetPayload,
} from "modules/cabinets/types/cabinets.interface";
import { UseMutationResult, UseQueryResult, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { getCabinetBackend } from "./backends";
import { CABINETS_ROUTES } from "./cabinets.api.enum";

export const useGetCabinets = (): UseQueryResult<Cabinet[]> => {
  const setSnack = useSnack();

  const getCabinets = async (): Promise<Cabinet[]> => {
    const cabinetBackend = await getCabinetBackend();
    return await cabinetBackend.getCabinets();
  };

  return useQueryWithLoading(CABINETS_ROUTES.GET_ALL, () => getCabinets(), {
    onError() {
      setSnack({
        title: "Erreur interne!",
        severityType: "error",
      });
    },
  });
};

export const useGetCabinet = (
  id: string | undefined
): UseQueryResult<Cabinet> => {
  const setSnack = useSnack();

  const getCabinet = async (): Promise<Cabinet> => {
    const cabinetBackend = await getCabinetBackend();
    return await cabinetBackend.getCabinet(id);
  };

  return useQueryWithLoading(CABINETS_ROUTES.GET + id, () => getCabinet(), {
    onError() {
      setSnack({
        title: "Erreur interne!",
        severityType: "error",
      });
    },
    enabled: !!id,
  });
};

export const useCreateCabinet = (): UseMutationResult<
  Cabinet,
  Error,
  CreateCabinetPayload
> => {
  const setSnack = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const createCabinet = async (
    payload: CreateCabinetPayload
  ): Promise<Cabinet> => {
    const cabinetBackend = await getCabinetBackend();
    return await cabinetBackend.createCabinet(payload);
  };

  return useMutationWithLoading(
    (payload: CreateCabinetPayload) => createCabinet(payload),
    {
      onSuccess() {
        setSnack({
          title: "Cabinet créé avec succès!",
          severityType: "success",
        });

        queryClient.refetchQueries(CABINETS_ROUTES.GET_ALL);

        navigate("/admin/cabinets");
      },
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};

export const useUpdateCabinet = (
  id: string | undefined
): UseMutationResult<Cabinet, Error, CreateCabinetPayload> => {
  const setSnack = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const updateCabinet = async (
    payload: CreateCabinetPayload
  ): Promise<Cabinet> => {
    const cabinetBackend = await getCabinetBackend();
    return await cabinetBackend.updateCabinet(id, payload);
  };

  return useMutationWithLoading(
    (payload: CreateCabinetPayload) => updateCabinet(payload),
    {
      onSuccess() {
        setSnack({
          title: "Cabinet modifié avec succès!",
          severityType: "success",
        });

        queryClient.refetchQueries(CABINETS_ROUTES.GET_ALL);

        navigate("/admin/cabinets");
      },
      onError() {
        setSnack({
          title: "Erreur interne!",
          severityType: "error",
        });
      },
    }
  );
};
