export interface AuthenticatedUser {
  email: string;
  name: string;
  token: UserTokens;
  role: CabinetUserRole;
}

export interface UserTokens {
  accessToken: string;
  refreshToken: string;
}

export interface ValidateUser {
  password: string;
  email: string;
}

export enum CabinetUserState {
  PasswordNotSet = "password_not_set",
  PasswordSet = "password_set",
}

export enum CabinetUserRole {
  User = "user",
  Admin = "admin",
  SuperAdmin = "super_admin",
}

export interface ISignInWithUserPasswordPayload {
  email: string;
  password: string;
}