import { useGetCabinets } from "modules/cabinets/api";
import { useGetTablets } from "modules/tablet/api";
import { Tablet } from "modules/tablet/types/tablet.interface";
import { useCallback, useState } from "react";
import TabletTablet, { RowObjTablet } from "../default/components/tabletTable";

const Tablets = () => {
  const [searchName, setSearchName] = useState<string | undefined>(undefined);
  const { data, hasNextPage, fetchNextPage } = useGetTablets(searchName);
  const [currentPage, setCurrentPage] = useState(1);
  const flatData = data?.pages[currentPage - 1].data;
  const { data: cabinets } = useGetCabinets();

  const handleGetCabinetName = useCallback(
    (cabinetId: string) => {
      const cabinet = cabinets?.find((cabinet) => cabinet._id === cabinetId);
      return cabinet?.name;
    },
    [cabinets]
  );

  const handleFormatData = useCallback(
    (data: Tablet[]): RowObjTablet[] => {
      return data.map((item) => {
        console.log(handleGetCabinetName(item.cabinetId));
        console.log(item)
        return {
          id: item._id,
          serialNumber: item.serialNumber,
          cabinetName: handleGetCabinetName(item.cabinetId),
          activationDate: item.lastActivation,
        };
      });
    },
    [handleGetCabinetName]
  );

  const handleFetchNextPage = () => {
    if (hasNextPage) {
      fetchNextPage();
      setCurrentPage(currentPage + 1);
    }
  };

  const handleFetchPrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <div className="mt-5">
        {data?.pages.length > 0 && (
          <TabletTablet
            tableData={handleFormatData(flatData)}
            searchName={searchName}
            setSearchName={setSearchName}
            currentPage={currentPage}
            totalPage={data.pages.length}
            hasNextPage={hasNextPage}
            handleFetchNextPage={handleFetchNextPage}
            handleFetchPrevPage={handleFetchPrevPage}
          />
        )}
      </div>
    </div>
  );
};

export default Tablets;
