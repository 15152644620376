import { useSnack } from "common/hooks";
import { useCustomForm } from "common/hooks/src/api/useCustomForm";
import { Button } from "components/button";
import { CardWithTitle } from "components/card/CardWithTitle";
import InputField from "components/fields/InputField";
import { useCreateCabinet } from "modules/cabinets/api";
import { SubscriptionInfo } from "modules/cabinets/types/cabinets.interface";
import { FormEvent, useCallback, useState } from "react";
import { MdAddCircle, MdRemoveCircle } from "react-icons/md";

const CreateCabinets = () => {
  const { mutate } = useCreateCabinet();
  const [nomDuCabinet, setNomDuCabinet] = useState("");
  const [address, setAddress] = useState("");
  const [siren, setSiren] = useState("");
  const [subscription, setSubscription] = useState<SubscriptionInfo>({
    numberOfTablets: 0,
    priceOfPerTablet: 0,
    subscriptionDuration: 0,
    subscriptionPrice: 0,
  });
  const { formValues: owner, setFormValues: setOwner } = useCustomForm<{
    name: string;
    email: string;
  }>({
    name: "",
    email: "",
  });
  const { formValues: owners, setFormValues: setOwners } = useCustomForm<
    | {
        name: string;
        email: string;
      }[]
    | undefined
  >(undefined);
  const setSnack = useSnack();

  const totalPrice =
    subscription.numberOfTablets * subscription.priceOfPerTablet +
    subscription.subscriptionPrice;

  const isSubmitDisabled =
    !nomDuCabinet ||
    !address ||
    !subscription.numberOfTablets ||
    !subscription.priceOfPerTablet ||
    !subscription.subscriptionDuration ||
    !subscription.subscriptionPrice ||
    !siren;

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    mutate({
      name: nomDuCabinet,
      address,
      owners,
      siren,
      subscription,
    });
  };

  const handleRenderOwners = () => {
    if (!owners) return;

    return owners.map((owner, index) => (
      <div
        key={index}
        className="flex w-full items-center justify-center gap-5 xl:max-w-[800px]"
      >
        <div className="grid w-full grid-cols-2 gap-5">
          <InputField
            variant="auth"
            extra="mb-3"
            label="Nom du gérant*"
            placeholder="Paul Dupont"
            id="nom-du-gerant"
            type="text"
            value={owners[index].name}
            onChange={(e) =>
              setOwners([
                ...owners.slice(0, index),
                { ...owners[index], name: e.target.value },
                ...owners.slice(index + 1),
              ])
            }
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Email*"
            placeholder="paul.dupont@mail.com"
            id="email-du-gerant"
            type="text"
            value={owners[index].email}
            onChange={(e) =>
              setOwners([
                ...owners.slice(0, index),
                { ...owners[index], email: e.target.value },
                ...owners.slice(index + 1),
              ])
            }
          />
        </div>
        <div className="mt-4 flex items-center justify-start">
          <MdRemoveCircle
            className="cursor-pointer text-xl text-red-500"
            onClick={() =>
              setOwners([...owners.slice(0, index), ...owners.slice(index + 1)])
            }
          />
        </div>
      </div>
    ));
  };

  const handleAddOwner = useCallback(() => {
    if (!owner.name || !owner.email) {
      setSnack({
        title: "Veuillez remplir tous les champs",
        severityType: "error",
      });
      return;
    }

    const newData = {
      name: owner.name,
      email: owner.email,
    };

    setOwners(owners ? [...owners, newData] : [newData]);

    setOwner({
      name: "",
      email: "",
    });
  }, [owner.email, owner.name, owners, setOwner, setOwners, setSnack]);

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
      <CardWithTitle title="Informations du cabinet">
        <div className="grid grid-cols-2 gap-5">
          <InputField
            variant="auth"
            extra="mb-3"
            label="Nom du cabinet*"
            placeholder="Cabinet Paul Dupont"
            id="nom-du-cabinet"
            type="text"
            value={nomDuCabinet}
            onChange={(e) => setNomDuCabinet(e.target.value)}
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Address*"
            placeholder="2 rue de la paix"
            id="address"
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Siren*"
            placeholder="0000000"
            id="siren"
            type="text"
            value={siren}
            onChange={(e) => setSiren(e.target.value)}
          />
        </div>
      </CardWithTitle>
      <CardWithTitle title="Informations des gérants">
        {handleRenderOwners()}
        <div className="flex w-full items-center justify-center gap-5 xl:max-w-[800px]">
          <div className="grid w-full grid-cols-2 gap-5">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Nom du gérant*"
              placeholder="Paul Dupont"
              id="nom-du-gerant"
              type="text"
              value={owner.name}
              onChange={(e) =>
                setOwner({
                  ...owner,
                  name: e.target.value,
                })
              }
            />
            <InputField
              variant="auth"
              extra="mb-3"
              label="Email*"
              placeholder="paul.dupont@mail.com"
              id="email-du-gerant"
              type="text"
              value={owner.email}
              onChange={(e) =>
                setOwner({
                  ...owner,
                  email: e.target.value,
                })
              }
            />
          </div>
          <div className="mt-4 flex items-center justify-start">
            <MdAddCircle
              className="cursor-pointer text-xl text-green-500"
              onClick={() => handleAddOwner()}
            />
          </div>
        </div>
      </CardWithTitle>
      <CardWithTitle title="Informations sur l'abonnement">
        <p className="-mt-2 mb-3 text-sm text-gray-400">
          (Les prix sont en euros)
        </p>

        <div className="grid grid-cols-2 gap-5">
          <InputField
            variant="auth"
            extra="mb-3"
            label="Nombre de tablette*"
            placeholder="2"
            id="number-of-tablets"
            type="number"
            value={subscription.numberOfTablets}
            onChange={(e) =>
              setSubscription({
                ...subscription,
                numberOfTablets: parseInt(e.target.value),
              })
            }
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Duréer de l'abonnement*"
            placeholder="365"
            id="subscription-duration"
            type="number"
            value={subscription.subscriptionDuration}
            onChange={(e) =>
              setSubscription({
                ...subscription,
                subscriptionDuration: parseInt(e.target.value),
              })
            }
            inputEnd={<span className="text-sm text-gray-400">jours</span>}
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Prix par tablette*"
            placeholder="100"
            id="subscription-price-per-tablet"
            type="number"
            value={subscription.priceOfPerTablet}
            onChange={(e) =>
              setSubscription({
                ...subscription,
                priceOfPerTablet: parseInt(e.target.value),
              })
            }
            inputEnd={<span className="text-sm text-gray-400">€/an</span>}
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label="Prix de l'abonnement*"
            placeholder="1000"
            id="subscription-price"
            type="number"
            value={subscription.subscriptionPrice}
            onChange={(e) =>
              setSubscription({
                ...subscription,
                subscriptionPrice: parseInt(e.target.value),
              })
            }
            inputEnd={
              <span className="text-sm text-gray-400">
                €/{subscription.subscriptionDuration} jours
              </span>
            }
          />
        </div>

        <div className="grid grid-cols-2 gap-5">
          {subscription.priceOfPerTablet > 0 &&
            subscription.numberOfTablets > 0 && (
              <div>
                <p className="text-sm text-gray-400">Total Tablette</p>
                <p className="text-lg font-bold">
                  {subscription.numberOfTablets * subscription.priceOfPerTablet}{" "}
                  €
                </p>
              </div>
            )}
          {subscription.subscriptionPrice > 0 && (
            <div>
              <p className="text-sm text-gray-400">Total </p>
              <p className="text-lg font-bold">{totalPrice} €</p>
            </div>
          )}
        </div>
        {totalPrice > 10000 && (
          <div className="mt-4">
            <p className="text-red-500">
              Stripe permet des paiements de moins de 10 000 €, nous devrons
              donc étaller le paiement en plusieurs mensualités.
            </p>
            <p className="mt-1.5 text-[#333]">
              Nombre de mensualités:{" "}
              <strong>{Math.ceil(totalPrice / 10000)}</strong>
            </p>
          </div>
        )}
      </CardWithTitle>
      <div className="mt-2 flex w-full justify-end">
        <div className="w-full max-w-xs">
          <Button onClick={handleSubmit} disabled={isSubmitDisabled}>
            Créer le cabinet
          </Button>
        </div>
      </div>
    </form>
  );
};

export default CreateCabinets;
