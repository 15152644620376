import Card from "components/card";
import { FunctionComponent, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { MdFileUpload } from "react-icons/md";

interface Props {
  name?: string;
  description?: string;
  uploadImage: (file: any) => void;
  image?: string | File;
  video?: string | File;
}

const Upload: FunctionComponent<Props> = ({
  description = "Uniquement les fichiers PNG et JPG sont autorisés",
  name = "Télécharger une image",
  uploadImage,
  image,
  video,
}) => {
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      if (acceptedFiles[0]) {
        uploadImage(acceptedFiles[0]);
      }
    },
    [uploadImage]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  if (video) {
    let videoUrl = video;

    // If video is a File object, create a URL for it
    if (video instanceof File) {
      videoUrl = URL.createObjectURL(video);
    }

    return (
      <Card className="h-full w-full gap-3 rounded-[20px] bg-white bg-clip-border p-3 font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none 2xl:grid-cols-11">
        <div
          className="col-span-5 h-full max-h-[200px] w-full rounded-xl bg-lightPrimary dark:!bg-navy-700 2xl:col-span-6"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <button className="h-full w-full rounded-xl border-[2px] border-dashed border-gray-200 py-3 dark:!border-navy-700 lg:pb-0">
            <video
              src={videoUrl as string}
              className="h-full w-full rounded-xl object-cover"
              controls
            />
          </button>
        </div>
      </Card>
    );
  }

  if (image) {
    let imageUrl = image;

    // If image is a File object, create a URL for it
    if (image instanceof File) {
      imageUrl = URL.createObjectURL(image);
    }

    return (
      <Card className="h-full w-full gap-3 rounded-[20px] bg-white bg-clip-border p-3 font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none 2xl:grid-cols-11">
        <div
          className="col-span-5 h-full max-h-[200px] w-full rounded-xl bg-lightPrimary dark:!bg-navy-700 2xl:col-span-6"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <button className="h-full w-full rounded-xl border-[2px] border-dashed border-gray-200 py-3 dark:!border-navy-700 lg:pb-0">
            <img
              src={imageUrl as string}
              alt="exercise"
              className="h-full w-full rounded-xl object-cover"
            />
          </button>
        </div>
      </Card>
    );
  }

  return (
    <Card className="h-full w-full gap-3 rounded-[20px] bg-white bg-clip-border p-3 font-dm shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none 2xl:grid-cols-11">
      <div
        className="col-span-5 h-full w-full rounded-xl bg-lightPrimary dark:!bg-navy-700 2xl:col-span-6"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <button
          className={`flex h-full w-full flex-col items-center justify-center rounded-xl border-[2px] border-dashed py-3 lg:pb-0 ${
            isDragActive
              ? "border-brand-500 dark:!border-brand-500"
              : "border-gray-200 dark:!border-navy-700"
          }`}
        >
          <MdFileUpload className="text-[80px] text-brand-500 dark:text-white" />
          <h4 className="text-xl font-bold text-brand-500 dark:text-white">
            {name}
          </h4>
          <p className="mt-2 text-sm font-medium text-gray-600">
            {description}
          </p>
        </button>
      </div>
    </Card>
  );
};

export default Upload;
