import axios from "axios";
import { environment } from "common/environment";
import { store } from "common/store";
import { refreshTokenAction, resetUser } from "modules/auth/store";
import { QueryClient } from "react-query";

export const axiosInstance = axios.create({
  baseURL: environment.API_URL,
  headers: {
    Authorization: "",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = store.getState().user.user?.token.accessToken;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const config = error.config;

    const page = window.location.pathname;

    if (page === "/auth/sign-in") {
      return Promise.reject(error);
    }

    const originalRequest = config;

    if (
      error.response &&
      error.response.status === 401 &&
      error.config.url === "/cabinet/users/refresh-token"
    ) {
      store.dispatch(resetUser());
      return Promise.reject(error);
    }

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      await store.dispatch(refreshTokenAction()).then(() => {
        const token = store.getState().user.user?.token.accessToken;
        originalRequest.headers["Authorization"] = "Bearer " + token;
        return axios(originalRequest);
      });

      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: 1000 * 60 * 60 * 24,
      cacheTime: 1000 * 60 * 60 * 24,
    },
  },
});

export const getFromApi = async (
  url: string,
  params?: Record<string, string>
) => {
  try {
    const { data } = await axiosInstance.get(url, {
      headers: params ? params : {},
    });
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export async function deleteFromApi(
  url: string,
  params?: Record<string, string>
) {
  try {
    const { data } = await axiosInstance.delete(url, {
      headers: params ? params : {},
    });
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
}

export async function postFromApi<T>(
  url: string,
  body: T,
  params?: Record<string, string>
) {
  try {
    const { data } = await axiosInstance.post(url, body, {
      headers: params ? params : {},
    });
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
}

export async function putFromApi<T>(
  url: string,
  body?: T,
  params?: Record<string, string>
) {
  try {
    const { data } = await axiosInstance.put(url, body, {
      headers: params ? params : {},
    });
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
}

export async function patchFromApi<T>(
  url: string,
  body?: T,
  params?: Record<string, string>
) {
  try {
    const { data } = await axiosInstance.patch(url, body, {
      headers: params ? params : {},
    });
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
}
