import { SuccessResponse } from "common/common.interface";
import { environment } from "common/environment";
import { Pagination } from "modules/exercises/types/exercises.interface";
import { CreateProtocolPayload, Protocol } from "modules/protocol/types/protocol.interface";

export interface IProtocolBackend {
  getProtocols(
    page: number,
    limit: number,
    searchName: string | undefined
  ): Promise<Pagination<Protocol>>;
  getProtocol(id: string): Promise<Protocol>;
  createProtocol(payload: CreateProtocolPayload): Promise<Protocol>;
  updateProtocol(id: string, payload: CreateProtocolPayload): Promise<Protocol>;
  removeProtocol(id: string): Promise<SuccessResponse>;
}

let protocolBackendInstance: IProtocolBackend | undefined;

export async function getProtocolBackend(): Promise<IProtocolBackend> {
  if (protocolBackendInstance === undefined) {
    const mod = await import(`./${environment.CABINET_BACKEND}`);
    protocolBackendInstance = new mod.default() as IProtocolBackend;
  }
  return protocolBackendInstance;
}
