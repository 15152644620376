import { useCustomForm } from "common/hooks/src/api/useCustomForm";
import { Button } from "components/button";
import { CardWithTitle } from "components/card/CardWithTitle";
import InputField from "components/fields/InputField";
import { Select } from "components/select";
import { useGetCabinets } from "modules/cabinets/api";
import {
  useGetTablet,
  useRemoveTablet,
  useUpdateTablet,
} from "modules/tablet/api";
import { CreateTabletPayload } from "modules/tablet/types/tablet.interface";
import { FormEvent, useEffect, useMemo } from "react";
import "react-medium-image-zoom/dist/styles.css";
import { useParams } from "react-router-dom";

const UpdateTablet = () => {
  const id = useParams<{ id: string | undefined }>().id;
  const { data } = useGetTablet(id);
  const { mutate } = useUpdateTablet(id);
  const { mutate: removeTablet } = useRemoveTablet();
  const { formValues, setFormValues } = useCustomForm<CreateTabletPayload>({
    cabinetId: "",
    serialNumber: "",
  });
  const { data: cabinets } = useGetCabinets();
  const options = useMemo(() => {
    return cabinets && cabinets.length > 0
      ? cabinets.map((cabinet) => cabinet.name)
      : [];
  }, [cabinets]);

  const isSubmitDisabled = !formValues.cabinetId || !formValues.serialNumber;

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    mutate(formValues);
  };

  useEffect(() => {
    if (data) {
      setFormValues({
        cabinetId: data.cabinetId,
        serialNumber: data.serialNumber,
      });
    }
  }, [data, setFormValues]);

  const retrieveCabinetNameWithId = (cabinetId: string) => {
    const cabinet = cabinets?.find((cabinet) => cabinet._id === cabinetId);
    return cabinet?.name;
  };

  const retrieveCabinetIdWithName = (cabinetName: string) => {
    const cabinet = cabinets?.find((cabinet) => cabinet.name === cabinetName);
    return cabinet?._id;
  };

  return (
    <div className="flex flex-col gap-4">
      <CardWithTitle title="Informations du protocol">
        <div className="grid grid-cols-2 gap-5">
          <InputField
            variant="auth"
            extra="mb-3"
            label="Numéro de série*"
            placeholder="0A1B2C3D4E5F"
            id="serial-number"
            type="text"
            value={formValues.serialNumber}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                serialNumber: e.target.value,
              })
            }
          />
          <Select
            options={options}
            value={retrieveCabinetNameWithId(formValues.cabinetId)}
            onChange={(e) => {
              setFormValues({
                ...formValues,
                cabinetId: retrieveCabinetIdWithName(e.target.value),
              });
            }}
            label={"Nom du cabinet"}
          />
        </div>
      </CardWithTitle>
      <div className="mt-2 flex w-full justify-between">
        <div className="w-full max-w-xs">
          <Button onClick={() => removeTablet(id)} variant="alert">
            Suprimer
          </Button>
        </div>
        <div className="w-full max-w-xs">
          <Button onClick={handleSubmit} disabled={isSubmitDisabled}>
            Mêttre à jour
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UpdateTablet;
